<template>
    <v-card>
        <v-toolbar fixed dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('edit_customer')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-form>
                <v-container>
                    <v-row>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.fullName"
                                          :label="$t('full_name')"
                                          dense
                                              outlined
                                          required></v-text-field>
                        </v-col>
                        <!--<v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.firstName"
                                          label="First Name "
                                          required></v-text-field>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.lastName"
                                          label="Last Name"
                                          required></v-text-field>
                        </v-col>-->
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-radio-group v-model="customerData.gender" row :label="$t('gender')">
                                <v-radio :label="$t('male')" value="male"></v-radio>
                                <v-radio :label="$t('female')" value="female"></v-radio>
                                <v-radio :label="$t('others')" value="other"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-menu v-model="snackbar.dateMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="customerData.dateOfBirth"
                                                  :label="$t('dob')"
                                                  clearable
                                                  dense
                                              outlined
                                                  prepend-inner-icon="event"
                                                  readonly
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.dateOfBirth">

                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.nationality"
                                          :label="$t('nationality')"
                                          dense
                                              outlined
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-autocomplete v-model="customerData.country"
                                            :items="countries"
                                            :label="$t('country')"
                                            clearable
                                            single-line
                                            item-text="value"
                                            dense
                                              outlined
                                            item-value="id"
                                            @change="getState"></v-autocomplete>
                        </v-col>

                        <!--<v-col
            cols="12"
            sm="6"
            md="4"
    >
        <v-autocomplete
                v-model="customerData.state"
                :items="state"
                label="State"
                clearable
                single-line
                item-text="value"
                item-value="id"
        ></v-autocomplete>
    </v-col>-->

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-menu v-model="snackbar.dateMenu4"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="customerData.tourDate"
                                                  :label="$t('tour_date')"
                                                  clearable
                                                  prepend-inner-icon="event"
                                                  dense
                                              outlined
                                                  readonly
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.tourDate">

                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.ticketNumber"
                                          :label="$t('ticket_number')"
                                          dense
                                              outlined
                                          required></v-text-field>
                        </v-col>
                        <!--<v-col cols="12"
           sm="6" md="4">
        <v-autocomplete v-model="customerData.ageGroup" :items="AgeGroup"
                        item-text="id"
                        item-value="value"
                        label="Age Group"></v-autocomplete>
    </v-col>-->
                        <!--<v-col cols="12"
           sm="6" md="4">
        <v-autocomplete v-model="customerData.customerType" :items="PassengerTypes"
                        label="Customer Type"></v-autocomplete>
    </v-col>-->

                        <v-col cols="12"
                               sm="6" md="4">
                            <v-autocomplete v-model="customerData.activation" :items="Activation" item-text="value"
                                            item-value="id"
                                            dense
                                              outlined
                                            :label="$t('activation')"></v-autocomplete>
                        </v-col>

                        <v-col cols="12"
                               sm="6" md="4">
                            <v-autocomplete v-model="customerData.destination" :items="Destination" item-text="value"
                                            item-value="id"
                                            dense
                                              outlined
                                            :label="$t('destination')"></v-autocomplete>
                        </v-col>
                        <v-col cols="12"
                               sm="6" md="4">
                            <v-autocomplete v-model="customerData.duration" :items="Duration" item-text="value"
                                            item-value="id"
                                            dense
                                              outlined
                                            :label="$t('duration')"></v-autocomplete>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-autocomplete v-model="customerData.serviceID"
                                            :items="Services"
                                            :label="$t('services')"
                                            dense
                                              outlined
                                            item-text="value"
                                            item-value="id"></v-autocomplete>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-autocomplete v-model="customerData.packageName"
                                            :items="Packages"
                                            :label="$t('packages')"
                                            item-text="value"
                                            dense
                                              outlined
                                            item-value="id"></v-autocomplete>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.citizenShipNumber"
                                          :label="$t('citizenshipNumber')"
                                          dense
                                              outlined
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.passportNumber"
                                          :label="$t('passport_number')"
                                          dense
                                              outlined
                                          required></v-text-field>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.licenseNumber"
                                          :label="$t('licenseNumber')"
                                          dense
                                              outlined
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.otherID"
                                          :label="$t('otherID')"
                                          dense
                                              outlined
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.document"
                                          :label="$t('documents')"
                                          dense
                                              outlined
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-switch class="col-sm-6 col-md-4"
                                      v-model="customerData.isVerifiedDocument"
                                      :label="$t('is_verified_document')"
                                      clearable></v-switch>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.issuedBy"
                                          :label="$t('issued_by')"
                                          dense
                                              outlined
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-menu v-model="snackbar.dateMenu3"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="customerData.issuedDate"
                                                  :label="$t('issued_date')"
                                                  clearable
                                                  prepend-inner-icon="event"
                                                  dense
                                              outlined
                                                  readonly
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.issuedDate">

                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="editFormSubmit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import axios from "axios";

    export default {
        name: "HelicopterCustomerEditForm",
        data() {
            return {
                Activation: [],
                Duration: [],
                Destination: [],
                AgeGroup: [],
                customerData: {},
                customerFormData: [],
                message: {
                    status: true,
                    text: 'true',
                },
                snackbar: {
                    dateMenu: false,
                    dateMenu2: false,
                    dateMenu3: false,
                    dateMenu4: false,
                },
                countries: [],
                Packages: [],
                state: [],
                airList: [],
                Destinations: [],
                Services: [],
                PassengerTypes: ['Adult', 'Child', 'Old'],
                FlightClasses: ['a'],
                Issuer: ['E-Sewa'],
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            }
        },
        watch: {
            "customerData.country": {
                handler: async function (val) {
                    if (val) {
                        const stateList = await axios.get('Shared/StateListByCountryCode?CountryCode=' + val);
                        this.state = stateList.data
                    }
                },
                deep: true
            }
        },
        props: ['editItemIndex']
        ,
        computed: {},
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },

            editFormSubmit() {
                this.$delete(this.customerData, 'createdBy')
                this.customerData.IsImportFromExcel = false
                this.customerData.issuedDate = this.customerData.issuedDate == "" ? null : this.customerData.issuedDate
                axios.post('TourPackageBusiness/UpdateCustomerInformationAsync', this.customerData)
                    .then((response) => {
                        if (response.data.success) {
                            this.message.status = true
                            this.servicesData.data = response.data
                            this.servicesData.message = "Customer updated Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    })
                    .catch((response) => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                    })
            },
            async getState(val) {
                const stateList = await axios.get('Shared/StateListByCountryCode?CountryCode=' + val);
                this.state = stateList.data
            },
            async formData() {
                axios.get("Shared/CountryList").then(response => {
                    this.countries = response.data;
                }).catch(error => console.log(error.response))
                axios.get('TourPackageBusiness/DDLTourPackageBusinessDestinationByIDAsync').then(response => {
                    this.Destination = response.data
                }).catch(error => console.log(error.response))

                axios.get('TourPackageBusiness/DDLTourPackageBusinessDurationByIDAsync').then(response => {
                    this.Duration = response.data
                }).catch(error => console.log(error.response))

                axios.get('TourPackageBusiness/DDLTourPackageBusinessActivationByIDAsync').then(response => {
                    this.Activation = response.data
                }).catch(error => console.log(error.response))

                axios.get('TourPackageBusiness/DDLTourPackageBusinessServiceListAsync').then(response => {
                    this.Services = response.data
                }).catch(error => console.log(error.response))
                axios.get('TourPackageBusiness/DDLTourPackageBusinessPackageListAsync').then(response => {
                    this.Packages = response.data
                }).catch(error => console.log(error.response))
                axios.get("Shared/GetAgeGroupList").then(response => {
                    this.AgeGroup = response.data;
                }).catch(error => console.log(error.response))
                axios.get('Trekking/DDLTrekkingPackageListAsync').then(response => {
                    this.Packages = response.data
                }).catch(error => console.log(error.response))
            },

            async getEditData() {
                const CustomerData = await axios.get('TourPackageBusiness/GetCustomerInformationByIDAsync/' + this.editItemIndex)
                this.customerData = CustomerData.data

            }
        },
        mounted() {
            this.formData();
            this.getEditData()
        },
    }
</script>

<style scoped>

</style>